<template>
  <div>
    <b-card
      title="Broker Information"
      class="position-static"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group label="Select Broker">
                <validation-provider
                  #default="{ errors }"
                  name="Broker"
                  rules="required"
                >
                  <v-select
                    v-model="commissionObject.form.brokerID"
                    :options="brokersList"
                    label="name"
                    :state="errors.length > 0 ? false:null"
                    :reduce="name => name.id"
                    placeholder="Select Broker"
                    @input="selectBrokerObject"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="Object.keys(choosedBrokerObject).length !== 0"
              md="4"
            >
              <b-form-group label="Select Contract">
                <validation-provider
                  #default="{ errors }"
                  name="Contract"
                  rules="required"
                >
                  <v-select
                    ref="contractName"
                    v-model="commissionObject.form.leadID"
                    :options="leadsList"
                    label="full_name"
                    :state="errors.length > 0 ? false:null"
                    :reduce="full_name => full_name.leadID"
                    placeholder="Select Contract"
                    @input="selectLeadObject"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="Object.keys(choosedLeadObject).length !== 0"
              md="4"
            >
              <b-form-group label="Product Type">
                <b-form-input
                  v-model="choosedLeadObject.contract.type"
                  placeholder="Product Type"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="Object.keys(choosedLeadObject).length !== 0"
              md="4"
            >
              <b-form-group label="Product">
                <b-form-input
                  v-model="choosedLeadObject.contract.product"
                  placeholder="Product"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="Object.keys(choosedBrokerObject).length !== 0"
              md="4"
            >
              <b-form-group label="Broker Type">
                <b-form-input
                  v-model="choosedBrokerObject.broker.type"
                  placeholder="Broker Type"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="Object.keys(choosedLeadObject).length !== 0"
              md="4"
            >
              <b-form-group label="Annual Premium">
                <b-form-input
                  v-model="choosedLeadObject.contract.premium_amount"
                  placeholder="Annual Premium"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="Object.keys(choosedLeadObject).length !== 0"
              md="4"
            >
              <b-form-group label="Currency">
                <b-form-input
                  v-model="choosedLeadObject.contract.currency"
                  placeholder="Currency"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="Object.keys(choosedLeadObject).length !== 0"
              md="4"
            >
              <b-form-group label="Frequency">
                <b-form-input
                  v-model="choosedLeadObject.contract.frequency"
                  placeholder="Frequency"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="Object.keys(choosedLeadObject).length !== 0"
              md="4"
            >
              <b-form-group label="Status">
                <b-form-input
                  v-model="choosedLeadObject.contract.status"
                  placeholder="Status"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card
      v-if="leadsList.length > 0 && Object.keys(choosedLeadObject).length !== 0"
      title="Commission Details"
      class="position-static"
    >

      <validation-observer ref="simpleRules">
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeatAgain"
        >

          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="'item' + index"
            ref="row"
          >

            <!-- Item Name -->
            <b-col md="3">
              <b-form-group
                label="Lead (Product)"
                label-for="lead-name"
              >
                <b-form-input
                  id="lead-name"
                  v-model="item.leadNameWithProduct"
                  type="text"
                  placeholder="Lead (Product)"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group
                label="Premium"
                label-for="premium"
              >
                <validation-provider
                  #default="{ errors }"
                  name="premium"
                  rules="required"
                >
                  <b-form-input
                    v-model="item.premium"
                    type="number"
                    placeholder="eg. 5000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group
                label="Premium Currency"
                label-for="premium_currency"
              >
                <v-select
                  v-model="item.premiumCurrencyID"
                  :options="currenciesList"
                  label="name"
                  :reduce="name => name.id"
                  placeholder="Select Currency"
                />
              </b-form-group>
            </b-col>

            <!-- Cost -->
            <b-col md="2">
              <b-form-group
                label="Commission"
                label-for="commission"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Commission"
                  rules="required"
                >
                  <b-form-input
                    v-model="item.commission"
                    type="number"
                    placeholder="eg. 5000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Quantity -->
            <b-col md="2">
              <b-form-group
                label="Commission Currency"
                label-for="currency"
              >
                <v-select
                  v-model="item.currencyID"
                  :options="currenciesList"
                  label="name"
                  :reduce="name => name.id"
                  placeholder="Select Currency"
                  @input="calculateCurrencySum"
                />
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="1"
              md="1"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-dark"
                class="btn-icon"
                @click="removeItem(index)"
              >
                <feather-icon
                  size="25"
                  icon="XIcon"
                  class="mt-1"
                />
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
          <b-row style="direction: rtl">
            <b-col
              v-for="(item, index) in sumArray"
              :key="'sum' + index"
              md="2"
            >
              Total {{ item.currency }}  <b class="ml-2">{{ Number(item.value).toLocaleString() }}</b>
            </b-col>

          </b-row>
        </b-form>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="repeatAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Duplicate</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="ml-50"
          @click="scrollToTop"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add Another</span>
        </b-button>
      </validation-observer></b-card>
    <b-card
      v-if="items.length > 0 && Object.keys(choosedLeadObject).length !== 0"
      title="Create Total Commission"
      class="position-static"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group label="Collection due date">
                <validation-provider
                  #default="{ errors }"
                  name="Date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="commissionObject.form.date"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="createCommissionFunction"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Create Commission</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-danger"
          @click="cancelCommission"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>Cancel</span>
        </b-button>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard, BCol, BForm, BFormGroup, BRow, BFormInput, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'AddCommission',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      commissionObject: {
        form: {},
        url: 'admin/commissions',
        storeName: 'commissions',
      },
      choosedLeadObject: {},
      choosedBrokerObject: {},
      sumArray: [],
      items: [],
      nextTodoId: 1,
      required,
      sortedBrokersList: [],
    }
  },
  computed: {
    ...mapGetters({
      brokersList: 'users/brokersList',
      currenciesList: 'currencies/currencyList',
      leadsList: 'users/brokersLeadsList',
    }),
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    await this.loadAllInsuranceCurrenciesFunction()
    await this.loadBrokersFunction()
  },
  mounted() {
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      loadBrokers: 'get',
      loadAllInsuranceCurrencies: 'get',
      loadAllLeads: 'get',
      createCommission: 'add',
    }),
    async loadBrokersFunction() {
      await this.loadBrokers({
        storeName: 'users',
        url: '/admin/users/brokers',
        commitUrl: 'users/SET_BROKERS',
      })
    },
    async sortBrokers() {
      return this.brokersList.sort((a, b) => {
        // Split full names into first and last names
        const firstNameA = a.split(' ')[0]
        const lastNameA = a.split(' ')[1]

        const firstNameB = b.split(' ')[0]
        const lastNameB = b.split(' ')[1]

        // Compare last names first
        if (lastNameA < lastNameB) {
          return -1
        } if (lastNameA > lastNameB) {
          return 1
        }
        // If last names are the same, compare first names
        if (firstNameA < firstNameB) {
          return -1
        } if (firstNameA > firstNameB) {
          return 1
        }
        return 0 // Full names are identical
      })
    },
    async loadAllLeadsFunction(brokerID) {
      await this.loadAllLeads({
        storeName: 'users',
        url: `/admin/users/broker/leads/${brokerID}`,
        commitUrl: 'users/SET_BROKERS_LEADS',
      })
    },
    async loadAllInsuranceCurrenciesFunction() {
      await this.loadAllInsuranceCurrencies({
        storeName: 'currencies',
        url: '/admin/settings/currency',
        commitUrl: 'currencies/SET_CURRENCIES',
      })
    },
    selectBrokerObject(brokerID) {
      this.choosedLeadObject = {}
      this.commissionObject.form = { brokerID }
      this.items = []
      this.choosedBrokerObject = this.brokersList[this.brokersList.map(value => value.id).indexOf(brokerID)]
      this.loadAllLeadsFunction(brokerID)
    },
    selectLeadObject(leadID) {
      this.choosedLeadObject = this.leadsList[this.leadsList.map(value => value.leadID).indexOf(leadID)]
      this.choosedLeadObject.contract.premium_amount = Number(this.choosedLeadObject.contract.premium_amount).toLocaleString()
      this.repeatAgain()
    },
    calculateCurrencySum() {
      const accumulator = array => array.reduce((res, item) => res.set(item.currencyID, (res.get(item.currencyID) || 0) + +item.commission), new Map())
      this.sumArray = Array.from(accumulator(this.items), item => ({
        currency: this.currenciesList[this.currenciesList.map(value => value.id).indexOf(item[0])].name,
        value: item[1],
      }))
    },
    repeatAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        leadID: this.choosedLeadObject.contract.id,
        leadName: this.choosedLeadObject.leadName,
        leadNameWithProduct: `${this.choosedLeadObject.leadName} (${this.choosedLeadObject.contract.type})`,
        premiumCurrencyID: this.choosedLeadObject.contract.currencyID,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
      this.$refs.contractName.value = null
      this.$nextTick(() => this.$refs.contractName.focus())
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.calculateCurrencySum()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    cancelCommission() {
      this.choosedLeadObject = {}
      this.choosedBrokerObject = {}
      this.sumArray = []
      this.items = []
      this.commissionObject.form = {}
    },
    createCommissionFunction() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.commissionObject.form.items = this.items
          this.createCommission(this.commissionObject).then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.$router.replace({ name: 'all_commissions' })
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: error,
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  /* .repeater-form {
    overflow: hidden;
    transition: .35s height;
  } */
</style>
